import Icon from "@mkt/ui/components/common/Icon"

export default function CloseGray(props) {
  return (
    <Icon
      iconMarkup={
        '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1.58232 1.58207L14.7588 14.7585" stroke="#9B9B9B" stroke-linecap="square"/> <path d="M14.7588 1.58207L1.58232 14.7585" stroke="#9B9B9B" stroke-linecap="square"/> </svg>'
      }
      iconName="CloseGray"
      {...props}
    />
  )
}
