import DefaultLayout from "../../layout/DefaultLayout"
import { withFormMarketplace } from "@mkt/firebolt/config"
import ProductPageTemplate from "@mkt/ui/templates/ProductPage"
import ContentAcquirer from "./components/ContentAcquirer"

const Acquirer = ({ pageContext, location }) => {
  const AzulisLogo =
    "https://assets.iq.com.br/f/114280/x/c7943e9d94/logo-azulis-redirect.svg"
  return (
    <DefaultLayout pageContext={pageContext} location={location} >
      <ProductPageTemplate
        pageContext={pageContext}
        LogoRedirect={AzulisLogo}
        ComponentContent={ContentAcquirer}
      />
    </DefaultLayout>
  )
}

export default withFormMarketplace(Acquirer)
