import Button from "@mkt/ui/components/Azulis/Button"
import Img from "@mkt/ui/components/common/Img"

import * as S from "./styles"

const BannerAcquirer = ({ content, scrollForm }) => {
  const { title, price, price_old, image } = content
  return (
    <section css={S.BannerAcquirer}>
      <div css={S.BannerAcquirerContent}>
        <div css={S.BannerAcquirerImage}>
          <Img src={image?.filename} alt="banner maquininha de cartão" />
        </div>
        <div css={S.BannerAcquirerInfo}>
          <h2 css={S.BannerAcquirerTitle}>{title}</h2>
          <div css={S.BannerAcquirerPriceWrapper}>
            {price_old !== "" ? <s>De 12x de R$ {price_old}</s> : ""}

            <div className="text-wrapper">
              <span>Por 12x de</span>
              <p>R$ {price}</p>
            </div>

            <Button
              type="primary"
              color="inverted"
              htmlType="submit"
              onClick={scrollForm}
            >
              Peça já
            </Button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BannerAcquirer
