import Text from "@mkt/ui/components/Azulis/Text"
import Img from "@mkt/ui/components/common/Img"
import scrollTo from "@mkt/utils/scrollTo"
import BannerAcquirer from "../BannerAcquirer"
import AcquirerBenefits from "./AcquirerBenefits"
import AcquirerFeesTable from "./AcquirerFeesTable"
import * as S from "./styles"

const ContentAcquirer = ({ content, children }) => {
  function handleScrollToForm() {
    const formPosition = document.querySelector(".form-flow").offsetTop
    const headerHeight = document.getElementsByTagName("header")[0].clientHeight
    document.querySelector('[name="full_name"]').focus()
    scrollTo(formPosition - headerHeight)
  }

  return (
    <>
      <BannerAcquirer content={content} scrollForm={handleScrollToForm} />
      <div className="article-content">
        <div css={S.articleContentContainer}>
          <div css={S.articleContentWrap}>
            <div css={S.articleContentAcquirer}>
              <div css={S.acquirerFlags}>
                <Text as="h2" variant="HeadingFour">
                  Bandeiras aceitas:
                </Text>
                <ul css={S.acquirerFlagsList}>
                  {content?.flags?.map((flag, index) => (
                    <li
                      css={S.acquirerFlagsListItem}
                      key={`flag-item-${index}`}
                    >
                      <Img
                        src={flag?.content?.flag_image?.filename}
                        alt={flag?.name}
                      />
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <Text as="h2" variant="HeadingFour">
                  Taxas e prazos para recebimento:
                </Text>
                <AcquirerFeesTable content={content} />
              </div>
              <div>
                <Text as="h2" variant="HeadingFour">
                  Saiba mais sobre a {content?.title}
                </Text>
                <AcquirerBenefits content={content} />
              </div>
            </div>

            <div css={S.articleFormContainer} data-id-ui="form-flow">
              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContentAcquirer
