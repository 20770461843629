import * as S from "./styles"

const AcquirerFeesTable = ({ content }) => {
  const tableRow = content?.fees?.tbody
  return (
    <table css={S.acquirerFeesTable}>
      <thead>
        <tr>
          <th>&nbsp;</th>
          <th>0-2 dias</th>
          <th>30 dias</th>
        </tr>
      </thead>
      <tbody>
        {tableRow?.map((row, index) => (
          <tr key={`row-acquirer-${index}`}>
            <td className="payment-method">{row?.body[0]?.value}</td>
            <td className="payment-fee">{row?.body[1]?.value}</td>
            <td className="payment-fee">{row?.body[2]?.value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default AcquirerFeesTable
