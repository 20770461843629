import { css } from "@emotion/react"
import { above, below } from "@mkt/ui/styles/Breakpoints"
import { colors } from "@mkt/ui/styles/Tokens"

export const acquirerBenefitsList = css`
  columns: 2;
  margin-top: 16px;
  max-width: 590px;
  padding: 0 0 36px;
  width: 100%;

  ${above["m"]} {
    padding-right: 50px;
  }

  ${below["m"]} {
    columns: 1;
  }

  li {
    align-items: baseline;
    display: flex;
    justify-content: flex-start;
    font-size: 14px;
    line-height: 34px;
    list-style: none;

    &.negativeListItem {
      color: ${colors.gray.medium};
    }
    &.positiveListItem {
      color: ${colors.black};
    }

    .icon {
      max-height: 12px;
      max-width: 14px;
      margin-right: 16px;
    }
  }
`
