import { CheckBlue, CloseGray } from "@mkt/ui/icons/Azulis"

import * as S from "./styles"

const AcquirerBenefits = ({ content }) => {
  const mapBenefits = {
    "precisa-de-celular": "Precisa de celular",
    "tem-wifi": "Tem Wifi",
    "comprovante-impresso": "Comprovante impresso",
    bateria: "Bateria",
    "aceita-aproximacao": "Aproximação (NFC)",
    "frete-gratis": "Frete grátis",
    "taxa-de-adesao": "Taxa de adesão",
    "tem-aplicativo": "Tem aplicativo",
    "tem-conta-digital": "Tem conta digital",
    "possui-cartao": "Possui cartão",
  }

  return (
    <ul css={S.acquirerBenefitsList}>
      {content?.benefits?.map((benefit, index) => {
        const negativeBenefit = benefit?.value == "Não"
        const itemClass = negativeBenefit
          ? "negativeListItem"
          : "positiveListItem"
        const itemIcon = negativeBenefit ? <CloseGray /> : <CheckBlue />

        return (
          <li className={itemClass} key={`list-item-acquirer-${index}`}>
            {itemIcon}
            <p>
              <strong>{mapBenefits[benefit?.benefit]}: &nbsp;</strong>{" "}
              {benefit?.value}
            </p>
          </li>
        )
      })}
    </ul>
  )
}

export default AcquirerBenefits
