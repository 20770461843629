import { css } from "@emotion/react"
import { below } from "@mkt/ui/styles/Breakpoints"
import { colors } from "@mkt/ui/styles/Tokens"

export const acquirerFeesTable = css`
  border: none;
  border-collapse: collapse;
  margin: 16px 0 36px;
  width: 90%;

  ${below["m"]} {
    width: 100%;
  }

  th {
    background: ${colors.gray.lightest};
    font-size: 16px;
    font-weight: 300;
    line-height: 32px;
    padding: 10px 12px;
  }

  td {
    font-size: 16px;
    font-weight: 600;
    line-height: 32px;
    padding: 16px 12px;

    &.payment-fee {
      color: ${colors.blue.default};
      text-align: center;
    }
  }

  tr {
    border-bottom: 1px solid ${colors.gray.lightest};
  }
`
